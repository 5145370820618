import React from 'react';
import { Link } from 'gatsby';

import style from './category.module.scss';


const data = {
    riches: {
        className: style.riches,
        text: 'riches',
        url: '/riches'
    },
    honor: {
        className: style.honor,
        text: 'honor',
        url: '/honor'
    },
    life: {
        className: style.life,
        text: 'life',
        url: '/life'
    }
};

const Category = ( { type, inactive, ...props } ) => {
    const catData = data[ type ];
    let className = catData.className + ' ph2 ' + props.className;
    
    if( inactive ){
        className += ' ' + style.inactive_category;
    }
    
    return <span {...props} className={className }>
        <Link className='white no-underline' to={ catData.url }>
            {catData.text}
        </Link>
    </span>;
}

const Riches = ( props ) => <Category type = 'riches' { ...props } />
const Honor = ( props ) => <Category type = 'honor' { ...props } />
const Life = ( props ) => <Category type = 'life' { ...props } />

export {
    Riches,
    Honor,
    Life
};