import React from "react";
import { Link } from "gatsby";
import styles from "./categoryCard.module.scss";
import { Riches, Honor, Life } from './category';

const colorsClass = {
    riches: 'bg-green',
    honor: 'bg-red',
    life: 'bg-blue'
};

const categoryMap = {
    riches: Riches,
    honor: Honor,
    life: Life
}

const Category = ( {title} ) => {
    const Ca = categoryMap[title];
    
    return <Ca className={styles.titlePos } />
}


export default ({ title, articleBits, style }) => (
    <div className = { styles.cardWrapper } style = { style }>
        <Category title = { title }></Category>
        {/* <span className={styles.titlePos + ' ' + colorsClass[title]} >{title}</span> */}
        <div className = { styles.cardBG }>
            <ul className = { styles.linkList }>
                {
                    articleBits.map( bit => (
                        <li key={bit.path}><Link to={bit.path} className = 'link dim'>{bit.context.frontmatter.title}</Link></li>
                    ))
                }
            </ul>
        </div>
    </div>
)