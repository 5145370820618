import React from "react"
import { graphql, Link } from "gatsby"
import Footer from '../components/footer';
import Header from '../components/headnav';
import CategoryCard from '../components/categoryCard';
import Img from 'gatsby-image';
import { Riches, Honor, Life } from '../components/category';

import styles from './index.module.scss';
import useSiteMetaData from "../hooks/siteMetaData";

function extractCategory(category, data) {
	return data.articleBits.edges.filter(edge => edge.node.context.frontmatter.category === category)
		.map(a => a.node );
}

function getExcerpt( text ){
	return /<!-- readmore -->([\s\S]+)<!-- readmore -->/.exec( text )[ 1 ]
		.replace( /<h1>.*?<\/h1>/, '' );
}

export default ({ data }) => {
	const metaData = useSiteMetaData();

	return <div>
		<Header />
		<div className="w-100 flex flex-column pa0-ns ph5 items-center-ns justify-center relative" style={{height: '500px'}}>
			<Img className="w-100 top-0 left-0" style={{height: '500px', zIndex: -1, position: 'absolute'}} imgStyle={{height: '500px', filter: 'contrast( 200% ) brightness(.5) grayscale(100%)'}} fluid={data.headerImage.childImageSharp.fluid} />
			<div className="flex flex-column f1-ns f2-m pa3-ns f3 tracked near-white items-center w-80 lh-copy">
				<span className="tl mh5-ns">Humility is the fear of the Lord;</span>
				<span className="tl mh5-ns">its wages are <Riches /> and <Honor /> and <Life />.</span>
				<span className="tl self-end">- Proverbs 22:4</span>
			</div>
		</div>
		<div className = { styles.mainGrid }>
			<p className='pa3-ns pa2 w-90'>
				Humility is one of the most neglected and misunderstood virtues. Humility is one of the most important things that we can value as a culture, as individuals, as humans.
			</p>
			<p className='pa3-ns pa2 w-90'>
				Humility is... is dedicated to fostering the conversation about what humility is, why it is important, and how we can tangibly benefit from it.
			</p>
			<p className='pa3-ns pa2 w-90'>
				This site is filled with stories, scriptures, parables, and more to help us focus our minds' lens on what it means to cultivate such a difficult virtue.
				{/* If you have any feedback or would like to try and contribute to this site please feel free to contact me at <a href={`mailto:${metaData.email}`}>{metaData.email}</a>. */}
			</p>
			<div className={styles.articlesWrapper }>
				{data.recentArticles.edges.map( edge => {
					return <div>
						<h1><Link className = 'no-underline black' to={edge.node.path}>{edge.node.context.frontmatter.title}</Link></h1>
						<h3>{edge.node.context.frontmatter.date}</h3>
						<div dangerouslySetInnerHTML={{__html: getExcerpt( edge.node.context.article.html )}} />
						<Link to = { edge.node.path }>read more...</Link>
					</div>
				})}
			</div>
			{/* <CategoryCard title='riches' articleBits={extractCategory('riches', data)}></CategoryCard>
			<CategoryCard title='honor' articleBits={extractCategory('honor', data)}></CategoryCard>
			<CategoryCard title='life' articleBits={extractCategory('life', data)}></CategoryCard> */}
		</div>
		<Footer />
	</div>;
}

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		headerImage: file(relativePath: { eq: "images/index_hero.jpg" }) {
	      childImageSharp {
	        fluid( maxWidth: 2048 ){
	            ...GatsbyImageSharpFluid
	          }
	      }
		}
		recentArticles: allSitePage(limit: 3, filter: {context: {frontmatter: {category: {ne: null}, published: {eq: true}}}}, sort: {fields: context___frontmatter___date, order: DESC}) {
			edges {
				node {
					path
					context {
						frontmatter {
							published
							date
							category
							title
						}
						article {
							html
						}
					}
				}
			}
		}
		articleBits: allSitePage(limit: 3, filter: {context: {frontmatter: {category: {ne: null}, published: {eq: true}}}}, sort: {fields: context___frontmatter___date, order: DESC}) {
			edges {
				node {
					path
					context {
						frontmatter {
							published
							date
							category
							title
						}
						article {
							html
						}
					}
				}
			}
		}
	}
`;